<template>
  <div class="card">

    <div class="bg-blue-light p-1">
      <TitleButton
          :showBtn="false"
          :showAddNew="false"
          title="Daily Sales Report"
      />
    </div>

    <div class="my-2 px-2">
      <DateQuerySetter
          btnTitle="Go"
          @onClickGo="getSalesReportGeneral"
      >
        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
          <label for="colFormLabel" class="col-form-label">Consultant: </label>
          <v-select
              v-model="human_resource_id" class="w-100" placeholder="Consultant"
              :options="consultants" label="name" :reduce="(name) => name.id"
          />
        </div>
        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
          <label for="colFormLabel" class="col-form-label">Created By:</label>
          <v-select placeholder="Created By" :options="contactList" label="name"
              :reduce="(name) => name.id" v-model="updated_by" />
        </div>
        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
          <label for="colFormLabel" class="col-form-label">Bill type: </label>
          <v-select
              multiple
              placeholder="Select Bill Type"
              v-model="billType"
              :options="billTypeList"
              label="label"
              :reduce="label => label.value"
          />
        </div>
      </DateQuerySetter>
    </div>

    <div class="d-flex justify-content-end px-2 mb-2 gap-1">
      <button
        @click="exportTable"
        class="btn btn-outline-secondary btn-sm btn-download-invoice waves-effect"
      >
        Export
      </button>
      <button class="btn btn-primary btn-block waves-effect waves-float waves-light" @click="goToPrint">
        Print
      </button>
    </div>

    <div class="col-12 px-2">
      <ListTable :sales="sales"/>
    </div>
    <div class="mb-2"></div>

    <div class="px-2 position-absolute bottom-0">
      <Pagination
          :offset="offset"
          @onPageChange="onPageChange"
          ref="pagination"
      />
    </div>
    <Loader v-if="loading"/>
  </div>
</template>

<script setup>
import TitleButton from '@/components/atom/TitleButton';
import {useRoute, useRouter} from 'vue-router';
import DateQuerySetter from '@/components/atom/DateQuerySetter';
import ListTable from '@/components/molecule/company/hospital/hospital-admin/DailySalesReportTable.vue';
import handleCBusinesses from "@/services/modules/businesses";
import handleHospitalReport from "@/services/modules/hospital/report";
import {computed, inject, onMounted, ref} from "vue";
import Loader from "@/components/atom/LoaderComponent.vue";
import Pagination from "@/components/atom/Pagination.vue";
import {useStore} from "vuex";
import handleCompany from "@/services/modules/company";
import pdfPrinter from '@/services/utils/pdf/dailySalesReport';
import handleHospital from "@/services/modules/hospital";
import handleImportTracker from "@/services/modules/procurement/importTracker";
import handleInventory from "@/services/modules/inventory";
import filterNullFromObject from "@/services/utils/filterNullFromObject";
const {fetchHome} = handleInventory()
const { fetchContacts } = handleImportTracker();
const {fetchServiceList} = handleHospital()
const {fetchBusinessList} = handleCBusinesses()
const {fetchSalesReportGeneral} = handleHospitalReport()
const { fetchCompanyInfo } = handleCompany();
const { exportToPDF } = pdfPrinter();

const showError = inject('showError');
const showSuccess = inject('showSuccess');
const store = useStore()
const route = useRoute();
const router = useRouter();
const loading = ref(false);
const business = ref([])
const consultants = ref([])
const contactList = ref([])
const billTypeList = ref([])
let offset = ref(10)
const sales = ref([])
const pdfSales = ref([]);
const human_resource_id = ref(null)
const updated_by = ref(null)
const billType = ref(null)
const companyInfo = ref({})
const isPaginated = ref(true)
const payload = ref({
  'billType': null,
  'businessName': null,
  'consultantName': null,
  'createdBy': null
})

//computed
const companyId = computed(() => route.params.companyId);
const startDate = computed(() => route.query.start);
const endDate = computed(() => route.query.end);
const page = computed(() => route.query.page);
const token = computed(() => localStorage.getItem('token'));

//methods
const goToPrint = async() => {

  const date = {startDate: startDate.value, endDate: endDate.value };

  loading.value = true;

  await getSalesReportGeneral(false).then(() => {

    if(!pdfSales.value.length) return;
    exportToPDF(companyInfo.value, pdfSales.value, date, payload.value)
  })
}

const getQuery = () => {
  let query = '?company_id=' + companyId.value
  if (!!billType.value && billType.value.length > 0) {
    query += '&sale_type=' + billType.value
  }

  if (!!human_resource_id.value) {
    query += '&service_resource_id=' + human_resource_id.value
    payload.value.consultantName = consultants.value.find(el => el.id == human_resource_id.value).name
  }

  if (!!updated_by.value) {
    query += '&updated_by=' + updated_by.value
    payload.value.createdBy = contactList.value.find(el => el.id == updated_by.value).name
  }

  if (!startDate.value && !endDate.value) return query
  if (startDate.value) query += '&start_date=' + startDate.value
  if (endDate.value) query += '&end_date=' + endDate.value
  if (page.value) query += '&page=' + page.value
  if(isPaginated.value) query += '&is_paginated=' + isPaginated.value
  query += '&offset=' + offset.value
  return query
};
const getSalesReportGeneral = async (pagination = true) => {

  isPaginated.value = pagination;

  await fetchSalesReportGeneral(getQuery()).then(({status, data}) => {
    if (!status) {
      sales.value = []
      pdfSales.value = [];
      return resetPagination()
    }

    if(!isPaginated.value) {
      pdfSales.value = data;
      return;
    }

    sales.value = data.data
    if (data.data.length > 0) {
      setPagination(data)
    }
  }).catch((err) => {
    loading.value = false
  }).finally(() => {
    loading.value = false
  })
};


const setPaginationData = (data) => {
  store.commit('setPaginationData', data)
};
const resetPagination = () => {
  store.commit('resetPagination')
};


const setPagination = (data) => {
  setPaginationData({
    records: data.total,
    perPage: data.per_page,
    currentPage: data.current_page,
  })
}

const onPageChange = async (page) => {
  let routeQuery = Object.assign({}, route.query)
  routeQuery.page = page
  await router.push({path: route.path, query: routeQuery})
  await getSalesReportGeneral()
}

const exportTable = () => {

  const queryObject = {
    company_id: companyId.value,
    start_date: startDate.value,
    end_date: endDate.value,
    sale_type: billType.value,
    service_resource_id: human_resource_id.value,
    updated_by: updated_by.value,
    _token: token.value
  }

  const queryString = new URLSearchParams(filterNullFromObject(queryObject)).toString();

  let url = `${process.env.VUE_APP_BASE_URL}/export/reports/sales-report-general?${queryString}`
  let a = document.createElement("a")
  a.setAttribute("href", url)
  a.click()
}

//
onMounted(async () => {
  loading.value = true

  const companyQuery = `?company_id=${companyId.value}&search_type=all`

  const businessRes = fetchBusinessList(companyQuery)

  await Promise.all([
    businessRes.then(({data}) => {
      if (data) business.value = data
    }),
    fetchCompanyInfo(companyId.value).then((res) => {
      companyInfo.value = res.data
    }),
    fetchServiceList(companyQuery, 'human_resources').then(({status, data}) => {
      if (status) consultants.value = data
    }),
    fetchContacts(companyQuery).then(({status, data}) => {
      if (status) contactList.value = data
    })
  ]).then(() => {
    loading.value = false;
  }).catch((err) => {
    loading.value = false
  });

  fetchHome().then(res => {
    if (res.data) {
      for (const value of Object.keys(res.data.hospital_invoice_types)) {
        billTypeList.value.push({
          value: value,
          label: res.data.hospital_invoice_types[value]
        })
      }
    }
  })

  getSalesReportGeneral()
})
</script>
